import { LoadingError } from "../components/LoadingError"
import { axiosWithAuth } from "../helpers/AxiosInterceptor"
import { BASE_URL, NOTIFICATION_TYPES } from "../helpers/Constants"
import {
  boolToYesNo,
  getUserData,
  getUserEmail,
  InfoBubble,
  setUserData,
  userNotLimited
} from "../helpers/Helpers"
import ClipsTab from "./dashboard_tabs/ClipsTab"
import InfoTab from "./dashboard_tabs/InfoTab"
import RecordingsTab from "./dashboard_tabs/RecordingsTab"
import ScheduleTab from "./dashboard_tabs/ScheduleTab"
import StatsTab from "./dashboard_tabs/StatsTab"
import StreamsTab from "./dashboard_tabs/StreamsTab"
import React, { useState, useEffect } from "react"
import { Container, Tab, Loader, Header, Button, Accordion, Icon } from "semantic-ui-react"

export default function Dashboard({ defaultActiveIndex }) {
  const [siteInfo, setSiteInfo] = useState(null)
  const [siteInfoLoaded, setSiteInfoLoaded] = useState(false)
  const [siteInfoLoadError, setSiteInfoLoadError] = useState(false)
  const [userData, setUserDataInState] = useState(getUserData())

  const tabPanes = [
    {
      menuItem: "Site Info",
      render: () => <InfoTab siteInfo={siteInfo} />
    },
    {
      menuItem: "Streams",
      render: () => <StreamsTab siteInfo={siteInfo} />
    },
    {
      menuItem: "Schedule",
      render: () => <ScheduleTab siteInfo={siteInfo} />
    },
    {
      menuItem: "Recordings",
      render: () => <RecordingsTab siteInfo={siteInfo} />
    },
    {
      menuItem: "Clips",
      render: () => <ClipsTab siteInfo={siteInfo} />
    },
    {
      menuItem: "Stats",
      render: () => <StatsTab siteInfo={siteInfo} />
    }
  ]

  useEffect(() => loadSiteInfo(), [])

  function loadSiteInfo() {
    console.log("Entering loadSiteInfo()")

    setSiteInfoLoaded(false)
    const url = `${BASE_URL}admin/api/site/${userData && userData.adminForSite}`
    axiosWithAuth
      .get(url)
      .then((response) => {
        setSiteInfo(response.data)
        setSiteInfoLoaded(true)
      })
      .catch(() => {
        setSiteInfoLoaded(true)
        setSiteInfoLoadError(true)
      })
  }

  function toggleAlert(type) {
    const url = `${BASE_URL}admin/api/toggle-notification`
    axiosWithAuth
      .post(url, {
        type,
        value: userData[type]
      })
      .then(() => {
        const updatedUserData = {
          ...userData,
          [type]: userData[type] ? 0 : 1
        }
        setUserDataInState(updatedUserData)
        setUserData(updatedUserData)
      })
  }

  const buildNotificationItem = (title, infoMessage, type) => (
    <div>
      {title}&nbsp;
      <InfoBubble message={infoMessage} />: {boolToYesNo(userData[type])}&nbsp;
      {userNotLimited() && (
        <Icon
          className="clickable"
          name={userData[type] ? "toggle on" : "toggle off"}
          onClick={() => toggleAlert(type)}
        />
      )}
    </div>
  )

  const buildNotificationArea = () => (
    <>
      <Accordion
        style={{ marginBottom: "15px" }}
        panels={[
          {
            key: "alerts",
            title: "My Notification Settings",
            content: {
              content: (
                <>
                  {buildNotificationItem(
                    "Receive Monthly Statistics Reports",
                    `Receive monthly email outlining monthly usage. Emails will be sent on the first of each month to ${getUserEmail()}.`,
                    NOTIFICATION_TYPES.EMAIL
                  )}
                  {buildNotificationItem(
                    "Receive Camera & Stream Status Alerts",
                    `Receive email alerts when your parish's camera or stream unexpectedly go offline. Emails will be sent to ${getUserEmail()}.`,
                    NOTIFICATION_TYPES.ALERT
                  )}
                </>
              )
            }
          }
        ]}
      />
    </>
  )

  const buildDashboard = () => (
    <Container style={{ marginTop: "50px", width: "1300px" }}>
      {siteInfoLoadError ? (
        <LoadingError />
      ) : !siteInfoLoaded ? (
        <Loader active inline="centered" />
      ) : (
        siteInfo && (
          <div>
            <Container textAlign={"center"}>
              <Header as="h1" style={{ fontSize: "33px" }}>
                MassLivestream Admin Dashboard for <i>{siteInfo.name}</i>
              </Header>
              <a href={`${BASE_URL}churches/${siteInfo.slug}`} target="_blank" rel="noreferrer">
                <Button>View Public Page</Button>
              </a>
              {buildNotificationArea()}
            </Container>
            <Tab
              style={{ marginTop: "20px" }}
              menu={{ secondary: true, pointing: false }}
              panes={tabPanes}
              defaultActiveIndex={defaultActiveIndex}
            />
          </div>
        )
      )}
    </Container>
  )

  return buildDashboard()
}
